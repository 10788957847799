import React, { useContext } from "react";
import { Card, Collapse, Col } from "antd";
import { GlobalContext } from "../context/GlobalState";

const { Panel } = Collapse;

export const StoreInfoBox = (props) => {
  const { activeShop } = useContext(GlobalContext);

  const formatPhoneNumber = (number) => {
    let formatedNumber = number;

    //if number is a valid  int then continue else not a number and return as is
    if (!Number.isNaN(Number.parseInt(formatedNumber))) {
      formatedNumber = formatedNumber.toString().trim(); //make sure its a string now
      //check if it is a valid us phone number length
      //no  area code
      if (formatedNumber.length === 7) {
        return `${formatedNumber.substring(0, 3)}-${formatedNumber.substring(
          3
        )}`;
      } else if (formatedNumber.length === 10) {
        //area code
        return `(${formatedNumber.substring(0, 3)}) ${formatedNumber.substring(
          3,
          6
        )}-${formatedNumber.substring(6)}`;
      } else if (
        formatedNumber.length === 11 &&
        formatedNumber.charAt(0) === 1
      ) {
        //country code. It better be US or its wrong... we're in AZ after all
        return `+1 (${formatedNumber.substring(
          1,
          4
        )}) ${formatedNumber.substring(4, 7)}-${formatedNumber.substring(7)}`;
      }
    }
    return formatedNumber;
  };

  const StoreInfo = (props) => {
    return (
      <div style={{textAlign: "left"}}>
        Address:{" "}
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
            props.shop.name + " " + props.shop.address
          )}`}
          target="_blank"
          rel="noreferrer"
        >
          {props.shop.address}
        </a>
        <br />
        Phone:{" "}
        {props.shop.phone ? (
          <a href={`tel:${props.shop.phone}`}>
            {formatPhoneNumber(props.shop.phone)}
          </a>
        ) : (
          "N/A"
        )}
        <br />
        {props.shop.website ? (
          <a href={`${props.shop.website}`} target="_blank" rel="noreferrer">
            Website
          </a>
        ) : (
          ""
        )}
        <br />
        <div>
          {!!props.shop.searchMatches && props.shop.searchMatches.length > 0 && (
            <div>
              Search Matches:
              {props.shop.searchMatches.map((term) => (
                <>
                  <div>-{term}</div>
                </>
              ))}
            </div>
          )}
        </div>
        <Collapse ghost>
          <Panel header="View all recently ordered products">
            <div style={{ maxHeight: "40vh", overflow: "scroll"}}>
              {props.shop.productsOrdered.map((product, i) => {
                //remove name after - as this is usally the same name as before slightly reworded.
                // let indexOfDash = product.indexOf("-");
                 let name = product.itemName;
                // if (indexOfDash > 0) {
                //   name = product.substring(0, indexOfDash);
                // }
                return <div key={i}>{name}</div>;
              })}
            </div>
          </Panel>
        </Collapse>
      </div>
    );
  };

  return (
    !!activeShop &&
    (props.type === "sider" ? (
      <Card style={{maxHeight: "95vh"}}>
        <div>
          <h3>
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                activeShop.properties.name + " " + activeShop.properties.address
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              {activeShop.properties.name}
            </a>
          </h3>
          <StoreInfo shop={activeShop.properties} />
        </div>
      </Card>
    ) : (
      <div style={{maxHeight: "95vh"}}>
        <h3>
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
              activeShop.properties.name + " " + activeShop.properties.address
            )}`}
            target="_blank"
            rel="noreferrer"
          >
            {activeShop.properties.name}
          </a>
        </h3>
        <Col md={0} lg={0} xl={0}>
          <StoreInfo shop={activeShop.properties} />
        </Col>
      </div>
    ))
  );
};
