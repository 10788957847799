import "./App.css";
import { MapArea } from "./components/MapArea";
import { MapSidebar } from "./components/MapSidebar";
import { Layout } from "antd";
import React, { useState, useContext } from "react";
import { GlobalProvider, GlobalContext } from "./context/GlobalState";
import { ListView } from "./components/ListView";
import { MapView } from "./components/MapView";
import { ViewSwitcher } from "./components/ViewSwitcher";
import { InformationPopup } from "./components/InformationPopup";

const { Content } = Layout;

function App() {
  //if on small screen can toggle list view or map view

  return (
    <div className="App">
      <GlobalProvider>
        <InformationPopup />
        <ViewSwitcher />
      </GlobalProvider>
      <a
        href="https://www.flaticon.com/free-icons/location"
        title="location icons"
      >
        Location icons created by Pixel perfect - Flaticon
      </a>
    </div>
  );
}

export default App;
