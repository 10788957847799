import "../App.css";
import { MapArea } from "./MapArea";
import { MapSidebar } from "./MapSidebar";
import { Layout } from "antd";
import React from "react";

const { Content } = Layout;

export const MapView = () => {
  return (
    <div className="MapView">
      <Layout>
        <MapSidebar />
        <Content>
          <MapArea />
        </Content>
      </Layout>
    </div>
  );
};
