import React, { useContext, useEffect, useRef } from "react";
import { Menu, Layout } from "antd";
import { GlobalContext } from "../context/GlobalState";
import { SearchBox } from "./SearchBox";
import { ToggleSortButton } from "./ToggleSortButton";
const { Sider, Footer } = Layout;

export const MapSidebar = () => {
  const { displayLocations, setActiveShop, activeShop } =
    useContext(GlobalContext);

  const scrollRefs = useRef([]);

  useEffect(() => {}, [displayLocations]);

  function handleClick(e) {
    //get the index of clicked local
    const locationIndex = Number.parseInt(e.key);
    setActiveShop(displayLocations[locationIndex]);
  }
  return (
    <Sider
      breakpoint="md"
      collapsedWidth="0"
      width={300}
      style={{ background: "white" }}
      zeroWidthTriggerStyle={{
        zIndex: 999,
        marginTop: "20px",
        display: "none",
      }}
    >
      <h2>Stores</h2>
      <SearchBox />
      <ToggleSortButton />
      <Menu
        onClick={handleClick}
        selectedKeys={[`${displayLocations.indexOf(activeShop)}`]}
        style={{
          width: "100%",
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
      >
        {displayLocations
          .sort((a, b) => {
            //alphabatize the array first
            return a.properties.name >= b.properties.name;
          })
          .map((location, i) => {
            return (
              <Menu.Item key={i}>{`${location.properties.name} 
            ${
              isNaN(location.properties.distanceToUser)
                ? ""
                : "(" +
                  Number.parseFloat(location.properties.distanceToUser).toFixed(
                    1
                  ) +
                  "mi)"
            }`}</Menu.Item>
            );
          })}
      </Menu>

    </Sider>
  );
};
