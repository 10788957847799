import "../App.css";
import { Layout } from "antd";
import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../context/GlobalState";
import { ListView } from "./ListView";
import { MapView } from "./MapView";
import { getLocation, calcDistance } from "../helpers/locationHelper";

export const ViewSwitcher = () => {
  const { listView, setUserLocation, userLocation, displayLocations } =
    useContext(GlobalContext);

  //get location on app start
  useEffect(() => {
    //try to load location on load
    getLocation(setUserLocation);
  }, []);

  useEffect(() => {
    //calc distance when userLocation changes
    if (userLocation) {
      console.log("user loc ", userLocation);
      displayLocations.forEach((shopLocation) => {
        const shopCords = shopLocation.geometry.coordinates;
        const distance = calcDistance(
          shopCords[1],
          shopCords[0],
          userLocation.coords.latitude,
          userLocation.coords.longitude
        );
        shopLocation.properties.distanceToUser = distance;
        //console.log(`Distance betwween me and ${shopLocation.properties.name} is ${distance} miles`)
      });
      //console.log(displayLocations[7]);
    }
  }, [userLocation, displayLocations]);

  return (
    <div className="Switcher">{listView ? <ListView /> : <MapView />}</div>
  );
};
