import React, { createContext, useReducer } from "react";
import { getLocation } from "../helpers/locationHelper";
import { AppReducer } from "./AppReducer";

const initialState = {
  locations: [],
  displayLocations: [],
  activeShop: null,
  listView: false,
  userLocation: null,
  sortBy: "abc",
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // Actions for changing state

  function setLocations(item) {
    dispatch({
      type: "SET_LOCATIONS",
      payload: item,
    });
  }
  function setDisplayLocations(item) {
    dispatch({
      type: "SET_DISPLAY_LOCATIONS",
      payload: item,
    });
  }
  function setActiveShop(item) {
    dispatch({
      type: "SET_ACTIVE_SHOP",
      payload: item,
    });
  }
  function setCollapsed(item) {
    dispatch({
      type: "SET_COLLAPSED",
      payload: item,
    });
  }
  function toggleListView() {
    dispatch({ type: "TOGGLE_LIST_VIEW" });
  }

  function setUserLocation(location) {
    //when location is set sort things if needed

    dispatch({ type: "SET_USER_LOCATION", payload: location });

    // let sortedDisplay = JSON.parse(JSON.stringify(state.displayLocations)); //deep copy

    // sortedDisplay.sort((a, b) => {
    //   //alphabatize the array first
    //   if (a.properties.distanceToUser < b.properties.distanceToUser) {
    //     return -1;
    //   }
    //   if (a.properties.distanceToUser > b.properties.distanceToUser) {
    //     return 1;
    //   }
    //   return 0;
    // });

    // console.log("Sorted by distance");
    // console.log(sortedDisplay);
    // setDisplayLocations(sortedDisplay);
  }

  function toggleSortBy() {
    const sortBy = state.sortBy === "abc" ? "distance" : "abc";

    //if now sorting by distance get
    if (sortBy === "distance") {
      //updating location whener sorting is time intensive so just sort
      //getLocation(setUserLocation);
      let sortedDisplay = JSON.parse(JSON.stringify(state.displayLocations)); //deep copy

      sortedDisplay.sort((a, b) => {
        //alphabatize the array first
        if (a.properties.distanceToUser < b.properties.distanceToUser) {
          return -1;
        }
        if (a.properties.distanceToUser > b.properties.distanceToUser) {
          return 1;
        }
        return 0;
      });

      console.log("Sorted by distance");
      console.log(sortedDisplay);
      setDisplayLocations(sortedDisplay);
    } else {
      //else sort by abc
      let sortedDisplay = JSON.parse(JSON.stringify(state.displayLocations)); //deep copy

      sortedDisplay.sort((a, b) => {
        //alphabatize the array first
        if (a.properties.name < b.properties.name) {
          return -1;
        }
        if (a.properties.name > b.properties.name) {
          return 1;
        }
        return 0;
      });

      console.log("Sorted by alpha");
      console.log(sortedDisplay);
      setDisplayLocations(sortedDisplay);
    }

    dispatch({ type: "TOGGLE_SORTBY", payload: sortBy });
  }

  return (
    <GlobalContext.Provider
      value={{
        locations: state.locations,
        activeShop: state.activeShop,
        displayLocations: state.displayLocations,
        collapsed: state.collapsed,
        listView: state.listView,
        userLocation: state.userLocation,
        sortBy: state.sortBy,
        setLocations,
        setActiveShop,
        setDisplayLocations,
        setCollapsed,
        toggleListView,
        setUserLocation,
        toggleSortBy,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
