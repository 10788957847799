import React, { useContext, useEffect, useState, useRef } from "react";
import { Menu, Layout, Button, Modal, Row, Col } from "antd";
import { GlobalContext } from "../context/GlobalState";
import { SearchBox } from "./SearchBox";
import { StoreInfoBox } from "./StoreInfoBox";
import { GlobalOutlined } from "@ant-design/icons";
import { ToggleSortButton } from "./ToggleSortButton";
const { Sider } = Layout;

export const ListView = () => {
  const {
    displayLocations,
    setActiveShop,
    activeShop,
    toggleListView,
    userLocation,
    sortBy,
  } = useContext(GlobalContext);

  const scrollRefs = useRef([]);
  const [sortedLocations, setSortedLocation] = useState(displayLocations);

  // useEffect(() => {
  //   const sort = displayLocations.sort((a, b) => {
  //     //alphabatize the array first
  //     return (
  //       Number.parseFloat(a.properties.distanceToUser) >=
  //       Number.parseFloat(b.properties.distanceToUser)
  //     );
  //   });
  //   setSortedLocation(sort);
  //   console.log("sorted:")
  //   console.log(sort)
  // }, [displayLocations, userLocation, sortBy]);

  function handleClick(e) {
    //get the index of clicked local
    const locationIndex = Number.parseInt(e.key);
    setActiveShop(displayLocations[locationIndex]);
  }
  return (
    <div>
      <h2>Stores</h2>
      <Row style={{ width: "100%" }}>
        <Col span={20}>
          <SearchBox />
        </Col>
        <Col span={4}>
          <Button
            type="default"
            key="toggleListView"
            onClick={() => toggleListView()}
            style={{ height: "100%" }}
          >
            Map
            <br />
            <GlobalOutlined style={{ fontSize: "20px" }} />
          </Button>
        </Col>
      </Row>
      <ToggleSortButton/>
      <Modal visible={activeShop} onCancel={() => setActiveShop(null)}>
        <StoreInfoBox />
      </Modal>
      <Menu
        onClick={handleClick}
        selectedKeys={[`${displayLocations.indexOf(activeShop)}`]}
        style={{
          width: "100%",
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
      >
        {displayLocations.map((location, i) => {
          return (
            <Menu.Item key={i}>
              {`${location.properties.name} 
                  ${
                    isNaN(location.properties.distanceToUser)
                      ? ""
                      : "(" +
                        Number.parseFloat(
                          location.properties.distanceToUser
                        ).toFixed(1) +
                        "mi)"
                  }`}
            </Menu.Item>
          );
        })}
      </Menu>
    </div>
  );
};
