import React, { useContext, useState } from "react";
import { Tooltip, Input, Card, Row, Col } from "antd";
import { GlobalContext } from "../context/GlobalState";
//var stringSimilarity = require("string-similarity");
import { findBestMatch } from "string-similarity";
import { FilterFilled, FilterOutlined } from "@ant-design/icons";

const { Search } = Input;
export const SearchBox = () => {
  const { locations, setDisplayLocations, setActiveShop } =
    useContext(GlobalContext);
  const [filtered, setFiltered] = useState(false);

  const iconStyle = { fontSize: "20px", marginTop: "6px" };

  // useEffect(() => {
  //   //console.log("determining autocomplete options");
  //   let tempOptions = []
  //   locations.forEach(location => {
  //       //add the name, address, and products to the option list
  //       tempOptions.push(location.properties.name)
  //       tempOptions.push(location.properties.address)
  //   })
  //   setOptions(tempOptions)
  // }, [locations]);

  function handleSearch(searchTerm) {
    if (!searchTerm) {
      //if no searchterm not filtered
      setFiltered(false);
      //if no searchterm simpledisplay all locations and remove search match list
      locations.forEach((location) => (location.properties.searchMatches = []));
      setDisplayLocations(locations);
    } else {
      //if a search term exists filter
      //console.log("Search term: ", searchTerm);

      //console.log("locations: ", locations);
      setFiltered(true);
      setActiveShop(null);
      //first we map to a new array. This array is the same as the locations array  but  with an array added for search matches.
      //then we filter the new array and remove all elements with 0 matches
      let filterLocations = locations.map((shop, i) => {
        shop.properties.searchMatches = [];
        //filter out shops that do not have the product searched for in recent orders
        //return if  products, product brand, store name or address has term or similar
        //first join all searchable arrays and ensure all things same case
        // if (i === 0) {
        //   console.log("shop: ", shop);
        // }
        let searchableArrays = shop.properties.productsOrdered
          .map((product) => {
            // if (product.brand) {
            //   return [product.itemName.toLowerCase(), product.brand];
            // }
            return product.itemName.toLowerCase();
          })
          .concat([
            shop.properties.name.toLowerCase(),
            shop.properties.address.toLowerCase(),
          ])
          .flat(2);

        //remove duplicates mainly of brands
        searchableArrays = [...new Set(searchableArrays)];
        // //first just see if the large string array includes the exact phrase  if so add to the array
        // if (searchableArrays.includes(searchTerm)) {
        //   //console.log("found exact match")
        //   shop.properties.searchMatches.push(searchTerm)
        // }

        // if (i === 0) {
        //   console.log("Searchable arrays for shop: ");
        //   console.log(searchableArrays);
        // }
        let added = false;
        //if not try and find similarish things.
        const similarityThresholdWord = 0.75;
        const similarityThresholdBrand = .7;
        const similarityThresholdPhrase = 0.25;
        let bestMatches;
        try {
          bestMatches = findBestMatch(searchTerm, searchableArrays);
        } catch (error) {
          console.log(error);
          console.log("shop: ", shop);
          console.log("Searchable arrays for shop: ");
          console.log(searchableArrays);
        }
        //for each item check its score and if over threshold   add to matches list
        // if (i === 0) {
        //   console.log("best matches arrays for shop: ");
        //   console.log(bestMatches);
        // }
        bestMatches.ratings.forEach((ratingObject) => {
          if (ratingObject.rating > similarityThresholdPhrase) {
            shop.properties.searchMatches.push(ratingObject.target);
            added = true;
          }
        });

        //search brands if not added in product name
        if (!added) {
          shop.properties.productsOrdered.forEach((product) => {
            if (product.brand) {
              let brandMatches = findBestMatch(searchTerm, product.brand);
              //console.log("Brands matches")
              //console.log(brandMatches)
              for (let i = 0; i < brandMatches.ratings.length; i++) {
                let ratingObject = brandMatches.ratings[i];
                if (ratingObject.rating > similarityThresholdBrand) {
                  //if the brand matches closely add the product
                  shop.properties.searchMatches.push(
                    product.itemName + `(${product.brand[0]})`
                  );
                  added = true;
                  break;
                }
              }
            }
          });
        }

        if (!added) {
          /**
           * Search individual phrases of a term if not added previously
           * split on space so that individual words are matched instead of whole phrases.
           * ie gummys is similar to gummies but not to "gummies (10/10) thc-medical"
           */
          searchableArrays.forEach((term) => {
            const splitTerm = term.split(" "); //split into words each term
            const splitMatches = findBestMatch(searchTerm, splitTerm); //for the array of words find best matches
            splitMatches.ratings.forEach((ratingObject) => {
              //for each word eval rating and add whole term if needed
              if (ratingObject.rating > similarityThresholdWord && !added) {
                shop.properties.searchMatches.push(term);
                added = true;
              }
            });
          });
        }
        return shop;
      });

      filterLocations = filterLocations.filter(
        (shop) => shop.properties.searchMatches.length > 0
      );
      //console.log("Final filter locations: ");
      //console.log(filterLocations);
      //set display to locations with some value(s) in searchmatches
      setDisplayLocations(filterLocations);
    }
  }

  return (
    <Card title="Search here for products and stores:" size="small">
      <Row>
        <Col span={22}>
          <Tooltip
            title="Searches products as well as store names and addresses"
            mouseEnterDelay={0.3}
            placement="bottom"
          >
            <Search
              placeholder="Enter search here"
              onSearch={(text) => handleSearch(text.toLowerCase())}
              //style={{ width: 300}}
              allowClear
              //          options={["green halo"]}
            ></Search>
          </Tooltip>
        </Col>
        <Col span={2}>
          {filtered ? (
            <FilterFilled style={iconStyle} />
          ) : (
            <FilterOutlined style={iconStyle} />
          )}
        </Col>
      </Row>
    </Card>
  );
};
