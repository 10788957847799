import React, { useContext } from "react";
import { Menu, Layout, Button, Modal, Row, Col } from "antd";
import { GlobalContext } from "../context/GlobalState";
import { GlobalOutlined } from "@ant-design/icons";
import { getLocation } from "../helpers/locationHelper";

export const ToggleSortButton = () => {
  const { toggleSortBy, sortBy, userLocation, setUserLocation } = useContext(GlobalContext);

  return userLocation ? (
    <Button onClick={toggleSortBy}>
      {sortBy === "abc" ? "Sort by distance" : "Sort alphabetically"}
    </Button>
  ) : (
    <Button onClick={() => getLocation(setUserLocation)}>Get Your Location</Button>
  );
};
