export const AppReducer = (state, action) => {
  switch (action.type) {
    case "SET_LOCATIONS":
      return {
        ...state,
        locations: action.payload,
      };
    case "SET_DISPLAY_LOCATIONS":
      return {
        ...state,
        displayLocations: action.payload,
      };
    case "SET_ACTIVE_SHOP":
      return {
        ...state,
        activeShop: action.payload,
      };
    case "SET_COLLAPSED":
      return {
        ...state,
        collapsed: action.payload,
      };
    case "TOGGLE_LIST_VIEW":
      return {
        ...state,
        listView: !state.listView,
      };
    case "SET_USER_LOCATION":
      return {
        ...state,
        userLocation: action.payload,
      };
    case "TOGGLE_SORTBY":
      return {
        ...state,
        sortBy: action.payload,
      };
    default:
      return state;
  }
};
