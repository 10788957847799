import React, { useContext, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { Spin, Button, Col, Row } from "antd";
import { StoreInfoBox } from "./StoreInfoBox";
import { GlobalContext } from "../context/GlobalState";
import { SearchBox } from "./SearchBox";
import { UnorderedListOutlined } from "@ant-design/icons";
import { Icon } from "leaflet";

import userLocationIcon from "../userLocation.png";
import logo from "../haloLogoSmall.png";

let DEV_URL = "";
if (process.env.NODE_ENV === "development") {
  DEV_URL = "http://localhost:3000";
}

export const MapArea = () => {
  const {
    locations,
    activeShop,
    displayLocations,
    setActiveShop,
    setLocations,
    setDisplayLocations,
    toggleListView,
    userLocation,
  } = useContext(GlobalContext);

  const hoverStyle = {
    position: "absolute",
    top: "10px",
    left: "50px",
    backgroundColor: "white",
    zIndex: 999,
    boxShadow: "2px 2px 10px grey",
  };

  useEffect(() => {
    //when the map loads like pull in some sweet dataaaa
    //fetch("https://halo-product-map.herokuapp.com/locations")
    fetch(`${DEV_URL}/locations`)
      .then((res) => {
        return res.json();
      })
      .then(
        (results) => {
          //when load set both the master location list  and the display locaiton list to the new
          //if there is a user location calc distance for each
          setLocations(results.features);
          setDisplayLocations(results.features);
        },
        (error) => {
          console.log(error);
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ChangeView = ({ center }) => {
    if (center) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const map = useMap();
      map.flyTo(center);
    }
    return null;
  };

  return (
    <div
      className="mapSearchBox"
      style={{
        position: "relative",
      }}
    >
      <Spin spinning={locations?.lengh === 0}>
        <Col md={0} lg={0} xl={0} style={hoverStyle}>
          <Row>
            <Col span={20}>
              <SearchBox />
            </Col>
            <Col span={4}>
              <Button
                type="default"
                key="toggleListView"
                onClick={() => toggleListView()}
                style={{ height: "100%" }}
              >
                List
                <br />
                <UnorderedListOutlined style={{ fontSize: "20px" }} />
              </Button>
            </Col>
          </Row>
        </Col>
        <Col style={hoverStyle} xs={0} sm={0} md={24} lg={24} xl={24}>
          <StoreInfoBox type="sider" />
        </Col>
        <MapContainer center={[33.4484, -112.074]} zoom={8}>
          <ChangeView
            center={
              activeShop
                ? [
                    activeShop.geometry.coordinates[1],
                    activeShop.geometry.coordinates[0],
                  ]
                : false
            }
            zoom={10}
          />
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {!!activeShop && (
            <Popup
              onClose={() => {
                setActiveShop(null);
              }}
              position={[
                activeShop.geometry.coordinates[1],
                activeShop.geometry.coordinates[0],
              ]}
            >
              <StoreInfoBox />
            </Popup>
          )}
          {displayLocations.map((shop, i) => {
            return (
              <Marker
                key={i}
                position={[
                  shop.geometry.coordinates[1],
                  shop.geometry.coordinates[0],
                ]}
                eventHandlers={{
                  click: (e) => {
                    setActiveShop(shop);
                  },
                }}
              ></Marker>
            );
          })}

          {userLocation && (
            <Marker
              key="userPos"
              icon={
                new Icon({
                  iconUrl: userLocationIcon,
                  iconSize: [36, 36],
                  iconAnchor: [18, 36],
                })
              }
              position={[
                userLocation.coords.latitude,
                userLocation.coords.longitude,
              ]}
            >
              <Popup>Your Location</Popup>
            </Marker>
          )}
        </MapContainer>
        <img
          style={{
            position: "absolute",
            bottom: "25px",
            left: "10px",
            maxWidth: "100%",
            height: "auto",
            zIndex: 998,
          }}
          src={logo}
          alt="Halo Infusions and Extractions Logo"
        />
        <p
          style={{
            position: "absolute",
            bottom: "0px",
            left: "25px",
            maxWidth: "100%",
            height: "auto",
            zIndex: 998,
          }}
        >
          License - 0000153ESTXW47689762
        </p>
      </Spin>
    </div>
  );
};
