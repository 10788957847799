import React, { useContext, useState, useRef } from "react";
import { Modal, Button } from "antd";

export const InformationPopup = () => {
  const [showPopup, setShowPopup] = useState(true);
  return (
    <Modal
      title="Halo Infusions & Extractions product map"
      visible={showPopup}
      onCancel={() => setShowPopup(false)}
      footer={[
        <Button type="primary" key="gotIt" onClick={() => setShowPopup(false)}>
          Got it!
        </Button>,
      ]}
    >
      This map is provided as a tool to help you locate our products but does
      not guarantee their availability at the presented locations. Please
      contact the stores shown to verify current availability. Thanks and enjoy
      our products!
    </Modal>
  );
};
